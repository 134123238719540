/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #333333;
}

a:link {
  color: #1D7A96;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #14576C;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #1D7A96;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #1D7A96;
  background-color: transparent;
  text-decoration: underline;
}

/* CUSTOMIZE THE NAV BAR
-------------------------------------------------- */
/* Modify the background color */
.navbar-custom {
  background-color: #1D7A96;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: rgba(255,255,255,.8);
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: rgba(255,255,255,.5);
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #ffffff;
}

/* Mobile version */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-custom .navbar-toggle {
  border-color: #DDD;
}
.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
  background-color: #DDD;
}
.navbar-custom .navbar-toggle .icon-bar .navbar-toggler-icon {
  background-color: #CCC;
}
@media (max-width: 767px) {
  .navbar-custom .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .navbar-custom .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-custom .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
  }
}

/* other stuff */

.main-h1 {
  font-size: 4rem;
  color: #1D7A96;
  text-decoration: underline #96761D;
}

.main-h2 {
  color: #181C37;
  background-color: #e1eaed73;
  max-width: 50%;
  display: none;
}

.main-line {
  border-top: 3px double #96391d;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 2rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  top: 6rem;
  z-index: 10;
  left: 10%;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 42rem;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

/* Declare heights because of positioning of img element */
.carousel-item-small {
  height: 12rem;
}
.carousel-item-small > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 3rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
  margin-top: 1.5rem;
}

.featurette-main-lead {
  font-size: 1.5rem;
}

.featurette-image-small {
  max-width: 200px;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .main-h1 {
    font-size: 6rem;
  }

  .main-h2 {
    display: block;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 4rem;
  }
}
